import pinia from '@/store';
import App from './App.vue';
import router from '@/router';
import 'amfe-flexible';
import 'vant/lib/index.css';
import '@/styles/index.less';
import Vconsole from 'vconsole';
import directive from './directive';

if (import.meta.env.VITE_USER_NODE_ENV === 'test') {
  new Vconsole();
}
const app = createApp(App);

app.use(router);
app.use(pinia);
app.use(directive);

app.mount('#app');
console.log(1);
