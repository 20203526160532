/*
 * TODO 此处引入项目logo图标、项目名称的图片
 *  public文件夹里面也要添加logo图标
 * */
import logo from '@/assets/img/logo.png';

/*
 * TODO此处需要添加或者更换 SIGN_KEY签名
 * */
const SIGN_KEY = '8ku4jazkh2nkab7ccuyksuxssa3348uzsfdeupr3mr4yby2p2ptetr2wvfkxnaxc';
const BODY_CODE = 'hxqb';
let ossPath = '';

/*
 * TODO此处需要添加或者更换 ossPath动态域名地址
 * */
switch (import.meta.env.VITE_USER_NODE_ENV) {
  case 'pro':
    ossPath = 'https://ttxs-pro.oss-cn-chengdu.aliyuncs.com/open/domain';
    break;
  case 'test':
    ossPath = 'https://ttxs-testing.oss-cn-chengdu.aliyuncs.com/open/domain';
    break;
  default:
    ossPath = 'https://ttxs-testing.oss-cn-chengdu.aliyuncs.com/open/domain';
}
export default { logo, SIGN_KEY, ossPath, BODY_CODE };
