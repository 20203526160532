import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { storage } from '@/utils/storage';
import { useUserStore } from '@/store/modules/user';
import api from '@/utils/http/axiosKeyConfig';
import axios from '@/utils/http/axios';
import { getRouteAuth } from '@/utils/config';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
    },
    component: () => import('@/pages/login/index.vue'),
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      title: '获客管理端',
      authName: 'productList',
    },
    component: () => import('@/pages/home/index.vue'),
  },
  {
    path: '/customerDetail',
    name: 'customerDetail',
    meta: {
      title: '客户详情',
      authName: 'serviceList',
    },
    component: () => import('@/pages/customerDetail/index.vue'),
  },
  {
    path: '/saasProduct',
    name: 'saasProduct',
    meta: {
      title: '助贷产品',
      authName: 'productList',
    },
    component: () => import('@/pages/saasProduct/index.vue'),
  },
  {
    path: '/404',
    name: '404',
    meta: {
      title: '',
    },
    component: () => import('@/pages/404.vue'),
  },
  // 替代vue2中的'*'通配符路径
  { path: '/:pathMatch(.*)*', redirect: '/404' },
];

const router = createRouter({
  history: createWebHistory(), // hash 模式则使用 createWebHashHistory()
  routes,
});

router.beforeEach(async (to, from, next) => {
  /* 路由发生变化修改页面 title */
  if (to && to.meta && to.meta.title) document.title = String(to.meta.title);
  const { setAxiosBaseURL, axiosBaseURL, setImgBaseURL, imgBaseURL } = useUserStore();
  // 获取后台的动态接口域名
  if (!axiosBaseURL) {
    const res: any = await axios.get(api.ossPath + `/${api.BODY_CODE}` + '/50');
    if (res?.data?.url) setAxiosBaseURL(res.data.url + '/crm');
  }

  if (!imgBaseURL) {
    const res: any = await axios.get(api.ossPath + `/${api.BODY_CODE}` + '/20');
    if (res?.data?.url) setImgBaseURL(res.data.url);
  }

  // 是否有登录信息
  const isLogin = storage.getItem<{ token: string }>('userLoginInfo', 2)?.token;
  if (isLogin) {
    if (to.path === '/login') {
      // 已登录且要跳转的页面是登录页
      next({ path: '/' });
    } else {
      if (to.meta.authName) {
        await getRouteAuth(to.meta.authName as string);
        next();
      } else {
        next();
      }
    }
  } else {
    if (to.path === '/login') {
      next();
    } else {
      next({ path: '/login' });
    }
  }
});

export default router;
